// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h3yyBSI7vYSxKKspG5wJr {\n  background: #fff;\n  box-shadow: 0 4px 4px rgba(0,0,0,0.04);\n  border-radius: 4px;\n  border: 1px solid #dedede;\n}\n._3Bz7TNGTs-TiryZKgGZ03t {\n  width: 560px;\n  min-width: 320px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/InternalRedeem/RedeemPageStyles.styl"],"names":[],"mappings":"AAAA;EACE,gBAAY;EACZ,sCAAY;EACZ,kBAAe;EACf,yBAAO;AACT;AAEA;EACE,YAAO;EACP,gBAAW;AAAb","sourcesContent":[".issueContainer {\n  background: #FFFFFF;\n  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);\n  border-radius: 4px;\n  border 1px solid #dedede\n}\n\n.contentContainer {\n  width: 560px\n  min-width: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"issueContainer": "h3yyBSI7vYSxKKspG5wJr",
	"contentContainer": "_3Bz7TNGTs-TiryZKgGZ03t"
};
export default ___CSS_LOADER_EXPORT___;
