// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._38lZA_dcvpVdtpqthkMnVS._1ipnhh9Rh9vTL1E6ReirNc ._1Wpo2GS_94W1gJawd-LRqm {\n  border-top-color: rgba(0,0,0,0.8) !important;\n}\n._38lZA_dcvpVdtpqthkMnVS._3oO3j5BDh-tH88kCgzsSa_ ._1Wpo2GS_94W1gJawd-LRqm {\n  border-bottom-color: rgba(0,0,0,0.8) !important;\n}\n._38lZA_dcvpVdtpqthkMnVS ._37brNFuAuSDJ8yGkmzVb2a {\n  background-color: transparent;\n  border: none;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://src/components/Base/components/Tooltip/styles.styl"],"names":[],"mappings":"AAGQ;EACE,4CAAkB;AAF5B;AAOQ;EACE,+CAAqB;AAL/B;AAUI;EACE,6BAAkB;EAClB,YAAQ;EACR,UAAS;AARf","sourcesContent":[" .rc-tooltip {\n    &.rc-tooltip-placement {\n      &-top {\n        .rc-tooltip-arrow {\n          border-top-color: rgba(0, 0, 0, 0.8) !important;\n        }\n      }\n\n      &-bottom {\n        .rc-tooltip-arrow {\n          border-bottom-color: rgba(0, 0, 0, 0.8) !important;\n        }\n      }\n    }\n\n    .rc-tooltip-inner {\n      background-color: transparent;\n      border: none;\n      padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rc-tooltip": "_38lZA_dcvpVdtpqthkMnVS",
	"rc-tooltip-placement-top": "_1ipnhh9Rh9vTL1E6ReirNc",
	"rc-tooltip-arrow": "_1Wpo2GS_94W1gJawd-LRqm",
	"rc-tooltip-placement-bottom": "_3oO3j5BDh-tH88kCgzsSa_",
	"rc-tooltip-inner": "_37brNFuAuSDJ8yGkmzVb2a"
};
export default ___CSS_LOADER_EXPORT___;
